<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card card-shadow">
          <div class="container-fluid">
            <div class="row">
              <div class="col text-left">
                <div class="card-body">
                  <h5 class="card-title">New Recipe</h5>
                  <p class="card-text"></p>
                </div>
              </div>
              <div class="col text-center"></div>
              <div class="col text-right"></div>
            </div>
          </div>
          <form @submit.prevent="create()">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <b-button
                  :to="{ name: 'recipes' }"
                  variant="outline-primary"
                  class="mr-1"
                  ><i class="far fa-arrow-alt-circle-left"></i> Exit</b-button
                >
                <b-button type="submit" variant="outline-success">
                  <i class="far fa-save"></i> Save
                </b-button>
              </li>
              <li id="tab-content" class="list-group-item">
                <div class="form-group">
                  <label for="recipe-name">Name</label>
                  <input
                    name="name"
                    type="text"
                    class="form-control data-field"
                    id="recipe-name"
                    placeholder="e.g. My Recipe"
                    required
                    maxlength="100"
                    data-error="Name is required."
                    v-model="recipe.name"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    The name of the recipe (100 characters max).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-description">Description</label>
                  <textarea
                    name="description"
                    class="form-control data-field"
                    id="recipe-description"
                    placeholder="e.g. This is my awesome recipe"
                    rows="8"
                    maxlength="256"
                    v-model="recipe.description"
                  ></textarea>
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    A description of the recipe (256 characters max).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-url">Source URL</label>
                  <input
                    name="url"
                    type="text"
                    class="form-control data-field"
                    id="recipe-url"
                    placeholder="e.g. https://www.fooddelights.io/recipes/1"
                    maxlength="100"
                    v-model="recipe.url"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    Web URL where the recipe was found (if applicable).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-servings">Servings</label>
                  <input
                    name="servings"
                    type="number"
                    class="form-control data-field"
                    id="recipe-servings"
                    placeholder="e.g. 3"
                    v-model="recipe.servings"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    Number of servings recipe makes.
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-prep-time">Prep Time</label>
                  <input
                    name="prep_time"
                    type="number"
                    class="form-control data-field"
                    id="recipe-prep-time"
                    placeholder="e.g. 20"
                    v-model="recipe.prep_time"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    Estimated prep time (in minutes).
                  </div>
                </div>
                <div class="form-group">
                  <label for="recipe-cook-time">Cook Time</label>
                  <input
                    name="cook_time"
                    type="number"
                    class="form-control data-field"
                    id="recipe-cook-time"
                    placeholder="e.g. 30"
                    v-model="recipe.cook_time"
                  />
                  <span
                    class="glyphicon form-control-feedback"
                    aria-hidden="true"
                  ></span>
                  <div class="help-block with-errors">
                    Estimated cook time (in minutes).
                  </div>
                </div>
                <div class="form-group">
                  <b-form-checkbox switch v-model="recipe.public"
                    >Public</b-form-checkbox
                  >
                </div>
              </li>
              <li class="list-group-item">
                <b-button
                  :to="{ name: 'recipes' }"
                  variant="outline-primary"
                  class="mr-1"
                  ><i class="far fa-arrow-alt-circle-left"></i> Exit</b-button
                >
                <b-button type="submit" variant="outline-success">
                  <i class="far fa-save"></i> Save
                </b-button>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { db } from "../../firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import slugify from "slugify";

export default {
  name: "RecipeNew",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  data() {
    return {
      ref: "recipes",
      bind: ["slugs"],
      recipe: {
        name: "",
        description: "",
        url: "",
        servings: "",
        prep_time: "",
        cook_time: "",
        public: true,
        slug: ""
      }
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userAuthorName"])
  },
  methods: {
    create() {
      const _this = this;
      this.recipe.slug = this.getUniqueSlug();
      const recipe = { ...this.recipe };
      recipe.family_key = this.userProfile.family_key;
      recipe.author = this.userAuthorName;
      const listRecipe = this.getListRecipe(recipe);

      // Get a new firebase key for the recipe
      const key = db
        .ref()
        .child(this.env + this.ref)
        .push().key;

      // Insert the new recipe into the recipe object, family object, and public recipe object
      const updates = {};
      updates[this.env + this.ref + "/" + key] = recipe;
      updates[
        this.env + "families/" + this.userProfile.family_key + "/recipes/" + key
      ] = listRecipe;
      if (recipe.public) {
        updates[this.env + "public_recipes/" + key] = listRecipe;
      }

      db.ref()
        .update(updates)
        .then(() => {
          _this
            .addSlug(key)
            .then(() => {
              window.toastr.success("Recipe created successfully");
              _this.navigate("recipes");
            })
            .catch(error => {
              console.log(error);
              window.toastr.error("An error occurred while saving the recipe");
            });
        })
        .catch(error => {
          console.log(error);
          window.toastr.error("An error occurred while saving the recipe");
        });
    },
    getUniqueSlug() {
      let slug = slugify(this.recipe.name, { lower: true, strict: true });
      let modifier = 2;
      while (slug in this.slugs) {
        modifier++;
        slug = slug + "-" + modifier;
      }
      return slug;
    },
    addSlug(key) {
      const slug = this.recipe.slug;
      this.slugs[slug] = key;
      const slugs = { ...this.slugs };
      return this.updateObject("slugs", null, slugs);
    },
    getListRecipe(recipe) {
      return {
        name: recipe.name,
        description: recipe.description,
        author: recipe.author,
        slug: recipe.slug,
        family_key: recipe.family_key
      };
    }
  }
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -4px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.recipe-card-body-text {
  height: 130px;
  overflow: hidden;
  margin-bottom: 10px;
}

.recipe-row {
  padding-top: 4px;
  padding-left: 9px;
  padding-right: 9px;
}

.recipe-card {
  margin: 0;
  padding: 5px;
  min-width: 20%;
}

.card-img-top {
  height: 150px;
  object-fit: cover;
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-link {
  color: rgb(255, 152, 0);
  font-weight: 500;
}

#recipe-tags {
  margin-left: 0px;
}

.recipe-tag {
  margin-left: 5px;
}

.chip {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
  background-color: #424242;
}

.chip img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.chip-sm {
  display: inline-block;
  padding: 0;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 15px;
}

.chip-sm img {
  float: left;
  margin: 0 8px 0 -15px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.gold-star {
  color: gold;
  font-size: 1.2em;
}

#recipe-carousel {
  height: 300px;
  max-width: 600px;
  background-color: #424242;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}

#recipe-carousel .carousel-inner {
  height: inherit;
  max-width: inherit;
}

.recipe-carousel-image {
  object-fit: contain;
  object-position: 50% 50%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.form-group {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.form-inline .form-group {
  margin-left: 0;
}

#recipe-edit-tags .custom-select {
  width: auto;
}

.recipe-handle {
  width: 30px;
  height: 28px;
  float: left;
  cursor: pointer;
}

.ordered-list-group {
  list-style: decimal inside;
}

.ordered-list-group-item {
  display: list-item;
}

#ingredients-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#recipe-ingredients-list .btn-outline-secondary {
  color: #495057;
  background-color: white;
  background-image: none;
  border-color: #ced4da;
}

#ingredients th {
  border-top: 0;
}

.ingredients-amount-col {
  min-width: 285px;
}

.recipe-ingredients-amount {
  max-width: 100px;
  float: left;
}

.recipe-ingredients-unit {
  max-width: 185px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#instructions-container {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

#instructions .list-group-item {
  border: 0;
}

#recipe-instructions-list {
  list-style-type: none;
}

#recipe-instructions-list > li {
  min-height: 108px;
}

#recipe-instructions-list .row {
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

#recipe-instructions-list .row:first-of-type {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin-top: 22px;
}

#recipe-instructions-list .row:last-of-type {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  margin-bottom: 22px;
}

.col-ingredient-handle {
  width: 30px;
  margin-right: 5px;
  padding-top: 7px;
}

.col-ingredient-desc {
  width: calc(100% - 35px);
}

.col-ingredient-delete {
  width: 72px;
  margin-left: 5px;
}

.recipe-instructions-numeral {
  float: left;
  height: 38px;
  padding-top: 8px;
}

.recipe-instructions-textarea {
  height: 100px;
  width: 100%;
}

#images-container {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.explain-text {
  font-style: italic;
  margin-top: 5px;
  margin-left: 10px;
}

.recipe-sharing-row {
  margin-top: 15px;
}

#sharing-family-rows {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
</style>
